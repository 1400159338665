
.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.side-bar {
  display: flex;
  flex-direction: column;
  width: 14vw;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: whitesmoke;
  background-color: #34394c;
  border-bottom: 5px solid #89bf04;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 18pt;
}

.side-bar-body {
  
  padding-left: 6px;
  margin: 2px
}

.api-link {
  padding: 5px 0;
  /* margin-top: 10px; */
  font-size: 11pt;
  transition: all .1s ease;
  padding-left: 10px;
  text-decoration: none;
  display: block;
  color: #040404;
}

.api-link:hover {
  cursor: pointer;
  border-left: 5px solid crimson;
  transition: all .1s ease;
}

#api-data {
  width: 90vw;
  padding-left: 30px;
  padding-top: 30px;
  overflow-y: scroll;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.inputWithButton {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;

}
.inputWithButton form {

  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.inputWithButton input{
    width: 100%;
    border-radius: 30px;
    padding: 10px;
}

.inputWithButton button {
  font-size: 14px;
  border-radius: 30px;
  position: absolute;
  padding: 5px;
  right: 0;
  height: 80%;
  margin: 3px;
}

